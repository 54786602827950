import consumer from "./consumer"

consumer.subscriptions.create("MailboxEventsChannel", {
  connected() {
    console.log('connected to MailboxEvents');
  },

  disconnected() {
    console.log('disconnected from MailboxEvents');
  },

  received(data) {
    console.log('data received');
    const eventList = document.getElementById('mailbox-events');
    eventList.insertAdjacentHTML('afterbegin', data);
  }
});
