import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['link']

  text() {
    const link = this.linkTarget
    navigator.clipboard.writeText(link.textContent);
  }
}
