import consumer from "./consumer"

consumer.subscriptions.create("HeldMailCountChannel", {
  connected() {
    console.log('connected to HeldMailCount');
  },

  disconnected() {
    console.log('disconnected from HeldMailCount');
  },

  received(content) {
    console.log('data received');
    document.getElementById('holding-details').innerHTML = content;
  }
});
