// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

  all() {
    const monday = this.mondayTarget
    const tuesday = this.tuesdayTarget
    const wednesday = this.wednesdayTarget
    const thursday = this.thursdayTarget
    const friday = this.fridayTarget
    const saturday = this.saturdayTarget
    const sunday = this.sundayTarget

    monday.checked = true
    tuesday.checked = true
    wednesday.checked = true
    thursday.checked = true
    friday.checked = true
    saturday.checked = true
    sunday.checked = true
  }

  weekdays() {
    const monday = this.mondayTarget
    const tuesday = this.tuesdayTarget
    const wednesday = this.wednesdayTarget
    const thursday = this.thursdayTarget
    const friday = this.fridayTarget
    const saturday = this.saturdayTarget
    const sunday = this.sundayTarget

    saturday.checked = false
    sunday.checked = false
    monday.checked = true
    tuesday.checked = true
    wednesday.checked = true
    thursday.checked = true
    friday.checked = true
  }

  weekends() {
    const monday = this.mondayTarget
    const tuesday = this.tuesdayTarget
    const wednesday = this.wednesdayTarget
    const thursday = this.thursdayTarget
    const friday = this.fridayTarget
    const saturday = this.saturdayTarget
    const sunday = this.sundayTarget

    saturday.checked = true
    sunday.checked = true
    monday.checked = false
    tuesday.checked = false
    wednesday.checked = false
    thursday.checked = false
    friday.checked = false
  }

  none() {
    const monday = this.mondayTarget
    const tuesday = this.tuesdayTarget
    const wednesday = this.wednesdayTarget
    const thursday = this.thursdayTarget
    const friday = this.fridayTarget
    const saturday = this.saturdayTarget
    const sunday = this.sundayTarget

    monday.checked = false
    tuesday.checked = false
    wednesday.checked = false
    thursday.checked = false
    friday.checked = false
    saturday.checked = false
    sunday.checked = false
  }
}
